import { connect } from "react-redux";
import { formDataSelectors } from "../../../../store/formData/formData.selectors";
import TYPCModalOtpStep from "./typcModalOtpStep.component";
import {
  wizardSelectorsIsOtpNotValid,
  wizardSelectorsIsPhonePopupOtpSended,
  wizardSelectorsOtpBlockStatus,
  wizardSelectorsVerifyPhone,
} from "../../../../store/wizard/wizard.selectors";
import {
  setIsOptNotValid,
  setOtpBlockStatus,
  setIsPhonePopupOtpSended,
  setContactsOtpJwt,
} from "../../../../store/wizard/wizard.action";
import { utagView } from "../../../../store/utagStore/utagStore.action";

const mapStateToProps = (state) => {
  return {
    formData: formDataSelectors.getFormData(state),
    isOtpNotValid: wizardSelectorsIsOtpNotValid.getWizardIsOtpNotValid(state),
    isOtpSended:
      wizardSelectorsIsPhonePopupOtpSended.getWizardIsPhonePopupOtpSended(
        state,
      ),
    isVerifiedPhone: wizardSelectorsVerifyPhone.getWizardIsVerifyPhone(state),
    otpBlockStatus:
      wizardSelectorsOtpBlockStatus.getWizardOtpBlockStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsOtpSended: (isPhoneOtpSended) =>
      dispatch(setIsPhonePopupOtpSended(isPhoneOtpSended)),
    setIsOtpNotValid: (isOtpNotValid) =>
      dispatch(setIsOptNotValid(isOtpNotValid)),
    setOtpBlockStatus: (otpBlockStatus) =>
      dispatch(setOtpBlockStatus(otpBlockStatus)),
    utagView: (data) => dispatch(utagView(data)),
    setContactsOtpJwt: (jwt) => dispatch(setContactsOtpJwt(jwt)),
  };
};

const TYPCModalOtpStepContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TYPCModalOtpStep);
export default TYPCModalOtpStepContainer;
